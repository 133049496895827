import dynamic from "next/dynamic";
import Slider from "react-slick";
import Box  from '@mui/material/Box';
import { Fragment } from "react";
import Link from "next/link";

const PrevArrow = dynamic(() => import("@components/Components/Reusables/Slick/PrevArrow"));
const NextArrow = dynamic(() => import("@components/Components/Reusables/Slick/NextArrow"));
const HomeSliderImage = dynamic(() => import("./HomeSliderImage"));
  
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    autoplay: true,
    autoplayspeed: 100,
    speed: 3500,
    prevArrow: <PrevArrow sx={{left: '2px'}} />,
    nextArrow: <NextArrow sx={{right: '2px'}} />,
}

const HomeBanner2 = ({slides}) => {
    return (
        <>
            <Box
                sx={{
                    '.slick-slider': {
                        padding: 0,
                        marginBottom: '22px'
                    }
                }}
            >
                <Slider {...settings}>
                    {slides.map((slide, idx) => (
                        <Fragment key={slide.name}>
                            <Link href={slide.link_1}>
                              <HomeSliderImage slide={slide} idx={idx}/>
                            </Link>
                        </Fragment>
                    ))}
                </Slider>
            </Box>
        </>
  )
}

export default HomeBanner2